import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "date-picker",
      "style": {
        "position": "relative"
      }
    }}>{`Date picker`}</h1>
    <p>{`Componentes de data permitem que o usuário faça uma seleção específica através de um calendário. Por padrão, o calendário apresenta sempre o mês ou ano atual. Caso já tenha uma data inserida, o calendário apresenta o mês ou ano referente à esta data.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`O campo deve permitir que o usuário também insira o valor através de input do teclado.`}</li>
      <li parentName="ul">{`O campo deve ter apenas um tipo de entrada, e não deve ser divido em campos para cada informação (dia, mês, ano).`}</li>
    </ul>
    <h3 {...{
      "id": "date-range-picker",
      "style": {
        "position": "relative"
      }
    }}>{`Date Range Picker`}</h3>
    <ul>
      <li parentName="ul">{`Utilize quando a data selecionada for próxima à data atual ou com um período menor que 1 ano.`}</li>
      <li parentName="ul">{`Utilize placeholder indicando o formato da entrada de dados, por exemplo: dd/mm/aaaa ou mm/dd/aa, etc.`}</li>
    </ul>
    <h3 {...{
      "id": "week-range-picker",
      "style": {
        "position": "relative"
      }
    }}>{`Week Range Picker`}</h3>
    <ul>
      <li parentName="ul">{`Utilize-o caso os períodos selecionados devam começar em um domingo e terminar em um sábado.`}</li>
      <li parentName="ul">{`Caso coloque um valor na prop value do Week Range Picker, ele precisa ser uma semana, por exemplo: 02/05/2021 (Domingo) a 08/05/2021 (Sábado). Caso contrário, não vai marcar corretamente. Um exemplo de entrada inválida seria: 03/05/2021 (Segunda) a 05/05/2021 (Quarta).`}</li>
    </ul>
    <h3 {...{
      "id": "month-range-picker",
      "style": {
        "position": "relative"
      }
    }}>{`Month Range Picker`}</h3>
    <ul>
      <li parentName="ul">{`Use quando o período selecionado deve ser no mínimo um mês inteiro.`}</li>
      <li parentName="ul">{`Se o usuário deve selecionar um período com maior granularidade, como semanas ou dias, utilize os componentes acima.`}</li>
    </ul>
    <h2 {...{
      "id": "tipos-de-date-picker",
      "style": {
        "position": "relative"
      }
    }}>{`Tipos de Date picker`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Default</th>
      <td>Quando é necessário selecionar apenas uma data.</td>
    </tr>
    <tr>
      <th>Simples (sem calendário)</th>
      <td>
        Recomendado quando o usuário conhece a data e não precisa do calendário para antecipar as datas. Normalmente
        utilizado em datas de validade de cartão de crédito.
      </td>
    </tr>
    <tr>
      <th>Período em dias (Date Range Picker)</th>
      <td>Utilizado para selecionar um intervalo de datas.</td>
    </tr>
    <tr>
      <th>Período em semanas (Week Range Picker)</th>
      <td>Utilizado para selecionar um intervalo de semanas.</td>
    </tr>
    <tr>
      <th>Período em meses (Month Range Picker)</th>
      <td>Utilizado para selecionar um intervalo de meses.</td>
    </tr>
  </tbody>
    </table>
    <h3 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}</h3>
    <Demo src='pages/components/date-picker/DatePickerExample' mdxType="Demo" />
    <h3 {...{
      "id": "date-range-picker-1",
      "style": {
        "position": "relative"
      }
    }}>{`Date Range Picker`}</h3>
    <Demo src='pages/components/date-picker/DateRangePickerExample' mdxType="Demo" />
    <h3 {...{
      "id": "week-range-picker-1",
      "style": {
        "position": "relative"
      }
    }}>{`Week Range Picker`}</h3>
    <Demo src='pages/components/date-picker/WeekRangePickerExample' mdxType="Demo" />
    <h3 {...{
      "id": "month-range-picker-1",
      "style": {
        "position": "relative"
      }
    }}>{`Month Range Picker`}</h3>
    <Demo src='pages/components/date-picker/MonthRangePickerDefault' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      